@import "../../../styles/variables.module";

$extra-padding: 4rem;
$stations-width: 15.6rem;
$stations-width-mobile: 9.6rem;
$station-horizontal-padding: 1rem;

.wrapper {
  --stations-width: 15.6rem;
}

body:global(.arrow-navigation) {
  .radio_station {
    &:focus {
      @include media(">phoneXL") {
        transform: scale(1.2);
        filter: drop-shadow(0px 0px 24px #000000);
      }
    }
  }
}

body:global(.low-performance) {
  .header,
  .radio_station {
    transition: none !important;
  }
  .placeholder_item {
    &:before {
      display: none;
    }
  }
}

body:global(.car-mode) {
  $stations-width: 23rem;

  .wrapper {
    --stations-width: 23rem;
  }

  .radio_station {
    .station_title {
      transition: opacity 0.5s;
      text-align: center;
      -webkit-line-clamp: 2;
      position: absolute;
      width: 100%;
      padding: 0.8rem 0.8rem 0;
      opacity: 0;
    }
    &:focus {
      .station_title {
        opacity: 1;
      }
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  transform: translateY(2.4rem);
  padding: 0 0 0 1.6rem;
  position: relative;
  z-index: $z-index-base + 1;

  @include media(">tablet") {
    padding-right: 1.6rem;
  }

  @include media("<=tablet") {
    padding: 0;
    align-items: center;
  }

  h2 {
    font-size: 3.2rem;
    color: $color-light;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    @include media("<=tablet") {
      font-size: 2.4rem;
      line-height: 3.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .buttons {
    flex-shrink: 0;
    direction: ltr;
  }

  .arrow {
    &:first-child {
      margin-right: 1.6rem;
    }
    svg {
      width: 4rem;
      height: 4rem;
    }
    &.arrow_back {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.radio_list {
  overflow-x: auto;
  padding: calc(3.2rem + #{$extra-padding}) 1.6rem;
  margin: -1 * $extra-padding -1.6rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  html[dir="rtl"] & {
    @include media(">desktop720p") {
      padding: calc(3.2rem + #{$extra-padding}) 5rem;
      margin: -1 * $extra-padding -5rem;
      margin-left: -3.4rem;
    }
  }
  @include media("<=tablet") {
    padding: calc(3.2rem + #{$extra-padding}) 0rem $extra-padding;
  }

  @include media(">desktop720p") {
    padding: calc(3.2rem + #{$extra-padding}) 5rem;
    margin: -1 * $extra-padding -5rem;
    margin-right: -3.4rem;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.radio_station {
  padding: 1.6rem $station-horizontal-padding;
  transition: 0.5s all;
  object-fit: cover;
  cursor: pointer;
  border-radius: 8px;
  z-index: $z-index-base;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.4));
  display: inline-block;

  &:first-child {
    padding-left: 1.6rem;
  }

  &:hover {
    @include media(">phoneXL") {
      transform: scale(1.2);
      filter: drop-shadow(0px 0px 24px #000000);
    }
  }

  &:focus {
    outline: none;
  }

  .cover_wrapper {
    position: relative;
    width: var(--stations-width);
    height: var(--stations-width);
  }

  .cover {
    border-radius: 8px;
    box-shadow: inset 0 0 10px 7px $color-light-transparent-15;

    &.no_image {
      background: $color-light-transparent-15;

      svg, img {
        width: calc(var(--stations-width) - 2rem);
        height: calc(var(--stations-width) - 2rem);
        margin: 1rem;
        object-fit: contain;
      }
    }
  }

  .station_title {
    color: $color-light;
    font-size: 1.6rem;
    line-height: 2.4rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    max-width: var(--stations-width);

    @include media("<tablet") {
      max-width: $stations-width-mobile;
    }
  }

  &.show_station_names.show_station_names {
    margin-bottom: 3.2rem;
  }
}

.row {
  display: flex;

  &::after {
    content: "";
    flex: 0 0 calc(100vw - 17.2rem - 4.2rem);

    @include media("<=phoneXL") {
      flex: 0 0 calc(100vw - 17.2rem - 0.8rem);
    }
  }

  &.vertical {
    display: flex;
    flex-direction: column;
    align-items: center;

    &::after {
      content: "";
      flex: 0;
    }
    .station_title {
      display: none;
    }
    .radio_station {
      margin-bottom: 0;
    }
  }
  &.wrapped {
    overflow-x: hidden;
    flex-wrap: wrap;
    padding-bottom: 3rem;

    .radio_station {
      margin: 1.6rem;
    }

    .placeholder_item {
      @include media("<=phoneXL") {
        width: 11.2rem;
        height: 11.2rem;
      }
    }
    .radio_station {
      @include media("<=phoneXL") {
        margin: 0rem;
      }
    }
  }
}

.placeholder_item {
  box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
  border-radius: 4px;
  width: var(--stations-width);
  height: var(--stations-width);
  position: relative;
  overflow: hidden;
  flex-shrink: 0;

  @include media("<tablet") {
    width: $stations-width-mobile;
    height: $stations-width-mobile;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: var(--stations-width);
    background: linear-gradient(
      to right,
      transparent 0%,
      rgba(#e8e8e8, 0.2) 50%,
      transparent 100%
    );
    animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;

    @include media("<tablet") {
      width: $stations-width-mobile;
    }
  }
}

@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}

:export {
  radioStationOuterWidth: $stations-width + $station-horizontal-padding + 1.6rem;
  radioStationOuterWidthMobile: $stations-width-mobile +
    $station-horizontal-padding + 1.6rem;
}
